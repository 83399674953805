import React, { forwardRef } from 'react'
import Logo from '@/assets/couponLogo.webp'
import styles from './index.module.scss'
import dynamic from "next/dynamic";
const HeaderNav = dynamic(()=>import('../Nav/HeaderNav'))
const HeaderSearch = dynamic(()=>import('../Search/HeaderSearch'), {ssr: false})
export interface HeaderType {
  header: {
    logo: string
    menus: Array<{
      name: string
      path: string
    }>
    searchPlaceholder: string
    isMobile: boolean
  }
}


const HeaderMobileNav = dynamic(()=>import("@/components/MobileNav/HeaderMobileNav"),{
  // ssr: false
})

export default forwardRef(function Header({ header, isMobile }: any, ref) {
  return (
    <header
      ref={ref as React.ForwardedRef<HTMLElement>}
      className={styles.header}
    >
      <div className={styles.header_wrapper}>
        <a className={styles.logo_wrapper}>
         Powerpromocode
        </a>
      </div>
    </header>
  )
})
